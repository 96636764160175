import React from 'react';
import { Container, Typography, Card, CardContent, CardMedia, Grid, Box, Link } from '@mui/material';

const AboutPage = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 5, pt: 5 }}>
      <Typography variant="h4" align="center" gutterBottom>
        About Us
      </Typography>
      <Typography variant="body1" align="center" paragraph>
        私たちのビジョンは、エスプレッソの美味しさによって生活に感動を与える存在になることです。それは言い換えれば優れた香り分解能を持つ装置を作り、香り空間を認識することに他なりません。バリスタがさらなる表現能力を獲得することで、「神は細部に宿る（Der liebe Gott steckt im Detail）」を体現するコーヒー体験をエンドユーザーへお届けします。
      </Typography>

      <Typography variant="h5" align="center" gutterBottom>
        Meet Our Founder
      </Typography>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ mb: 4 }}>
            <CardMedia
              component="img"
              image="/自己紹介.png"
              alt="Takuma Okada"
              sx={{ width: '150px', height: '150px', margin: '16px auto 0', borderRadius: '50%' }}
            />
            <CardContent>
              <Typography variant="h6" align="center">
                Takuma Okada
              </Typography>
              <Typography variant="subtitle1" align="center" color="text.secondary" gutterBottom>
                Founder & IPA認定スーパークリエイター
              </Typography>
              <Typography variant="body2" align="center">
                エスプレッソの情熱家であり、高品質なコーヒーマシンを開発しています。IPA認定スーパークリエイターとして、革新的な技術とデザインを追求し続けています。
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Box component="section" textAlign="center" sx={{ mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1">
          Contact us at: <Link href="https://x.com/mtakuma32" target="_blank" rel="noopener noreferrer">https://x.com/mtakuma32</Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default AboutPage;
