import React from 'react';
import { Box, Card, CardContent, Typography, useTheme, Container, IconButton } from '@mui/material';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import notifications from './notificationsData'; // notificationsData.jsからデータをインポート
import { Link } from 'react-router-dom'; // 追加: react-router-dom から Link をインポート
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'; // 左矢印アイコンをインポート
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'; // 右矢印アイコンをインポート

// カスタム矢印コンポーネント
const PrevArrow = ({ className, style, onClick }) => (
  <IconButton 
    className={className} 
    onClick={onClick} 
    sx={{ 
      position: 'absolute', 
      top: '50%', 
      left: '-35px',  // 左側の位置調整
      transform: 'translate(0, -50%)', 
      zIndex: 2,      // 高い zIndex を設定
      backgroundColor: 'rgba(255, 255, 255, 0.6)', // 背景を追加して見やすく
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 1)', // ホバー時の背景
      }
    }}
  >
    <ArrowBackIosIcon />
  </IconButton>
);

const NextArrow = ({ className, style, onClick }) => (
  <IconButton 
    className={className} 
    onClick={onClick} 
    sx={{ 
      position: 'absolute', 
      top: '50%', 
      right: '-35px',  // 右側の位置調整
      transform: 'translate(0, -50%)', 
      zIndex: 2,      // 高い zIndex を設定
      backgroundColor: 'rgba(255, 255, 255, 0.6)', // 背景を追加して見やすく
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 1)', // ホバー時の背景
      }
    }}
  >
    <ArrowForwardIosIcon />
  </IconButton>
);
const AppleCard = ({ card, index }) => {
  return (
    <Link to={`/news/${index}`} style={{ textDecoration: 'none' }}>
      <Card 
        sx={{ 
          position: 'relative', 
          maxWidth: 345, 
          m: 1, 
          overflow: 'hidden', 
          borderRadius: '16px',
          boxShadow: 3 
        }}
      >
        {card.images && card.images.length > 0 ? (
          <img 
            src={card.images[0]} 
            alt={card.title} 
            style={{ width: '100%', height: '400px', objectFit: 'cover' }} 
          />
        ) : (
          <Box sx={{ width: '100%', height: '400px', backgroundColor: '#f0f0f0' }} />
        )}
        <CardContent 
          sx={{ 
            position: 'absolute', 
            top: 0, 
            left: 0, 
            width: '100%', 
            height: '100%', 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'flex-start', 
            alignItems: 'flex-start',    
            color: 'white', 
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            padding: '16px',
            boxSizing: 'border-box'
          }}
        >
          <Typography 
            variant="h6" 
            component="div" 
            sx={{ 
              mb: 1, 
              textAlign: 'left', 
              width: '100%' 
            }}
          >
            {card.tags ? card.tags.join(', ') : 'No Tags'}
          </Typography>
          <Typography 
            variant="h5" 
            component="div" 
            sx={{ 
              fontWeight: 'bold', 
              textAlign: 'left', 
              width: '100%' 
            }}
          >
            {card.title}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
};

const AppleCardsCarouselDemo = () => {
  const theme = useTheme();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />, // カスタム左矢印を指定
    nextArrow: <NextArrow />, // カスタム右矢印を指定
    responsive: [
      {
        breakpoint: theme.breakpoints.values.md,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: theme.breakpoints.values.sm,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Container sx={{ py: 5 }}>
      <Typography variant="h4" gutterBottom>
        Recent News
      </Typography>
      <Slider {...settings}>
        {notifications.map((notification, index) => (
          <Box key={index} sx={{ px: 1 }}>
            <AppleCard card={notification} index={index} /> {/* indexを渡す */}
            </Box>
        ))}
      </Slider>
    </Container>
  );
};

export default AppleCardsCarouselDemo;
